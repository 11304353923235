import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NewLanding from './screens/NewLanding';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsAndConditions from './screens/TermsAndConditions';

const App = () => {
	const client = new ApolloClient({
	  uri: "https://cakewalkweb.hasura.app/v1/graphql",
	  cache: new InMemoryCache(),
	});

	const router = createBrowserRouter([
	  {
	    path: "/",
	    element: <NewLanding />,
	  },
	  {
	    path: "/privacy_policy",
	    element: <PrivacyPolicy />,
	  },
	  {
	    path: "/terms_and_conditions",
	    element: <TermsAndConditions />,
	  },
	]);


	return(
		<ApolloProvider client={client}>
			<RouterProvider router={router} />
		</ApolloProvider>
	)
}

export default App;