const useValidateEmail = () => {
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

	return {
		isValidEmail
	}
}

export default useValidateEmail;