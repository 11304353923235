import {useState, useEffect} from 'react';

const useIsMobile = () => {
	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
	    setWidth(window.innerWidth);
	}
	useEffect(() => {
	    window.addEventListener('resize', handleWindowSizeChange);
	    return () => {
	        window.removeEventListener('resize', handleWindowSizeChange);
	    }
	}, []);

	return {
		isMobile: width <= 768
	}
}

export default useIsMobile;