import {useState, useRef} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {LIST_USERS} from '../graphQL/queries';
import {INSERT_USER} from '../graphQL/mutations';
import useValidateEmail from './useValidateEmail';

const useLandingPage = () => {
	const appleRef = useRef(null);
	const androidRef = useRef(null);
	const [state, setState] = useState({email: '', phone_type: '', promo: ''})
	const [activity, setActivity] = useState({loading: false, submitted: false})
	const {data, refetch} = useQuery(LIST_USERS)
	const {isValidEmail} = useValidateEmail()
	const [insertUser] = useMutation(INSERT_USER, {
		onCompleted: res => {
			setActivity({loading: false, submitted: true})
			res.insert_user_one.phone_type === 'apple' ? appleRef.current.play() : androidRef.current.play()
			refetch()
		}
	})

	function handleEmail(event) {
		setState(prev => {
			return {
				...prev,
				email: event.target.value
			}
		})
	}

	function handlePromo(event) {
		setState(prev => {
			return {
				...prev,
				promo: event.target.value
			}
		})
	}

	function handleApple(e) {
		setState(prev => {
			return {
				...prev,
				phone_type: 'apple'
			}
		})
	}

	function handleAndroid(e) {
		setState(prev => {
			return {
				...prev,
				phone_type: 'android'
			}
		})
	}

	function handleSubmit() {
		if(!isValidEmail(state.email)) return alert('Enter a valid email');
		if(data?.user.find(x => x.email === state.email)) return alert("Sorry, this email is already registered");
		if(!state.phone_type) return alert('Please choose your device OS');

		setActivity(prev => {
			return {
				...prev,
				loading: true
			}
		})
		insertUser({
			variables: {
				object: {
					...state
				}
			}
		})
	}

	return {
		...state,
		...activity,
		data,
		appleRef,
		androidRef,
		handleEmail,
		handlePromo,
		handleApple,
		handleAndroid,
		handleSubmit
	}
}

export default useLandingPage;