import {gql} from "@apollo/client";

export const LIST_USERS = gql`
	query myQuery {
		user {
			id
			name
			email
			created_at
			updated_at
			phone_type
		}
	}
`;