import React from 'react';
import '../styles/NewLanding.css';
import Countdown from './Countdown';
import Lottie from "lottie-react";
import {showLottie} from './Reusables';
import * as loadingLottie from '../assets/lottie/loading.json';
import * as submittedLottie from '../assets/lottie/done.json';
import useLandingPage from '../hooks/useLandingPage';
import {getTime} from '../utils/date';
import ReactPlayer from 'react-player/youtube'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import useIsMobile from '../hooks/useIsMobile';

const NewLanding = () => {
	const {
		data,
		email,
		promo,
		appleRef,
		androidRef,
		phone_type,
		loading,
		submitted,
		handlePromo,
		handleApple,
		handleAndroid,
		handleEmail,
		handleSubmit
	} = useLandingPage();

	const {isMobile} = useIsMobile();

	return(
		<div className="container">
			<div className="column">
				<img 
	      	alt=""
	      	src={require('../assets/images/new_logo_white.png')}
	      	style={{alignSelf: 'center', width: '240px', marginTop: 40}} />
			</div>
			<div style={{flexWrap: 'wrap', whiteSpace: 'pre-line', textAlign: 'center'}}>
				<text style={{display: 'flex', fontFamily: 'ArialHebrew', fontSize: 26, color: 'white', justifyContent: 'center', marginTop: 30}}>Available now on both iOS and Android</text>
				<text style={{display: 'flex', fontFamily: 'Arial', fontSize: 15, color: 'white', justifyContent: 'center', marginBottom: 10, }}>*Sign up now and get a free year of premium membership. (Limited Time Offer)</text>
			</div>
			<div style={{display: 'flex', justifyContent: 'center'}}>
		    <a href="https://apps.apple.com/us/app/cakewalk-video-tours-more/id1602865127">
			    <img 
		      	alt=""
		      	src={require('../assets/images/available_apple.png')}
		      	style={{width: '220px'}} />
		    </a>
		    <a href="https://play.google.com/store/apps/details?id=com.onedotnow.dotwalk&pcampaignid=web_share">
			    <img 
		      	alt=""
		      	src={require('../assets/images/GoogleAvailable.png')}
		      	style={{width: '220px'}} />
		    </a>
		  </div>
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
		    <div style={{width: isMobile ? '95%' : '55%', height: 500}}>
					<div style={{boxShadow: '1px 2px 9px black', width: '100%', height: '100%', borderRadius: isMobile ? 5 : 20}}>
			      <ReactPlayer
			      	light
			      	auto
			      	height='100%'
			      	width='100%'
			      	style={{borderRadius: isMobile ? 5 : 20, overflow: 'hidden'}}
			      	url='https://youtu.be/e8N02-lKU88' />
				  </div>
		    </div>
		  </div>
		</div>
	)
}

export default NewLanding;